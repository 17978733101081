
import { defineComponent, PropType, inject } from 'vue'
import AppInfo from '@/components/AppInfo.vue'
import PlatformBex from '@/components/PlatformBex.vue'
import PlatformMacos from '@/components/PlatformMacos.vue'
import PlatformWindows from '@/components/PlatformWindows.vue'
import PlatformMobile from '@/components/PlatformMobile.vue'
import PlatformWeb from '@/components/PlatformWeb.vue'
import { IApp, PLATFORM_TYPE, IPlatform } from '@/interfaces'
import { GlobalStore as GlobalStoreKey } from '@/symbolKeys'
import { canOpenAppUsingUrl } from '@/apps/app-url'

export default defineComponent({
  name: 'ShareDesktop',
  components: {
    AppInfo,
    PlatformBex,
    PlatformMacos,
    PlatformWindows,
    PlatformMobile,
    PlatformWeb
  },
  props: {
    appData: {
      type: Object as PropType<IApp>,
      required: true
    }
  },
  setup () {
    const GlobalStore = inject(GlobalStoreKey, {} as Record<string, boolean>)
    return {
      GlobalStore
    }
  },
  data () {
    return {
      platforms: [] as IPlatform[]
    }
  },
  computed: {
    activePlatform (): IPlatform {
      return this.platforms.find((platform: IPlatform) => platform.isActive) as IPlatform
    },
    showWhatIsTheDifferenceSection (): boolean {
      const displayedPlatformsList = this.platforms.filter((platform: IPlatform) => {
        if (platform.componentName === PLATFORM_TYPE.BEX) {
          return this.GlobalStore.isChromeExtensionCompatibleBrowser
        }
        return true
      })
      return displayedPlatformsList.length > 1
    }
  },
  methods: {
    onPlatformClick (platform: IPlatform): void {
      if (this.activePlatform === platform) {
        return
      }
      this.activePlatform.isActive = false
      platform.isActive = true
    }
  },
  created () {
    const platforms = [
      PLATFORM_TYPE.BEX,
      PLATFORM_TYPE.MOBILE
    ]
    if (canOpenAppUsingUrl(this.appData)) {
      platforms.push(PLATFORM_TYPE.WEB)
    }
    this.platforms = Object.values(platforms).map(platform => ({
      componentName: platform,
      isActive: false
    }))
    const platformBex = this.platforms.find((platform: IPlatform) =>
      platform.componentName === PLATFORM_TYPE.BEX)
    const platformMobile = this.platforms.find((platform: IPlatform) =>
      platform.componentName === PLATFORM_TYPE.MOBILE)
    if ((this.GlobalStore.isChromeExtensionCompatibleBrowser) && platformBex) {
      platformBex.isActive = true
    } else if (platformMobile) {
      platformMobile.isActive = true
    }
  }
})
