
import { defineComponent, inject } from 'vue'
import { GlobalStore as GlobalStoreKey } from '@/symbolKeys'

export default defineComponent({
  name: 'PlatformWindows',
  setup () {
    const GlobalStore = inject(GlobalStoreKey, {} as Record<string, boolean>)
    return { GlobalStore }
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
})
